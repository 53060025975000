import React, { useState, useEffect } from "react";
import Img from "../images/form_img1.jpg";
import { Col, Row } from "antd";
import GoogleLoginBtn from "../components/GoogleLogin";
import { Link, useLocation } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { PostApi, GetApi } from "../constant/Api";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLogin, setIsLogin] = useState(true);
  const [isSignUp, setSignUp] = useState(true);
  const handleClick = (isBtn) => {
    isBtn ? setIsLogin(!isLogin) : setSignUp(!isSignUp);
  };

  const clientId =
    "932737388320-ltbl50cuo9hgvag198hpnvdrqtc3a23r.apps.googleusercontent.com";

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
      gapi.load("client:auth2", start);
    }
  });

  const handleLogin = () => {
    const userJson = {
      email: email,
      password: password,
    };

    const userDJson = JSON.stringify(userJson);
    PostApi("post_LoginUser/", userDJson)
      .then((val) => {
        if (val.statusCode == 200) {
          setUserInfo(val.dtl);
          navigate("/#");
          window.location.reload();
        }

        console.log("Logging in with:", userInfo);
      })
      .catch((error) => {
        setUserInfo(null);
        alert(error);
      });
  };

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = secureLocalStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : {};
  });

  useEffect(() => {
    secureLocalStorage.setItem("userInfo", JSON.stringify(userInfo));
  }, [userInfo]);

  // Function to update user information
  const updateUser = (newUserInfo) => {
    setUserInfo(newUserInfo);
  };

  return (
    <div className="flex lg:mt-60 mt-32 lg:mx-72 mx-10 lg:mb-28 mb-5 justify-center items-center ">
      <div className="grid lg:grid-cols-2 grid-cols-1 lg:h-96  shadow-lg ">
        <div className="hidden lg:block w-full h-fit content-center p-6">
          <img
            src={Img}
            alt="Your image"
            className="object-cover w-full h-fit"
          />
        </div>
        <div className="grid w-full h-full content-center p-6">
          <div className="flex justify-start mb-6">
            <div>
              <h2 className="text-lg font-bold ">Нэвтрэх</h2>
            </div>
          </div>
          {/* Buttons for login with Google and Facebook */}
          {/* <div className="flex w-full">
            <GoogleLoginBtn />
          </div>
          <button className="bg-blue-700 text-white rounded-lg px-4 py-2 mb-4 w-full">
            Log in with Facebook
          </button> */}
          {/* Separator */}
          {/* <div className="flex items-center mb-4">
            <hr className="flex-grow border-gray-300" />
            <span className="text-gray-400 mx-4">Or</span>
            <hr className="flex-grow border-gray-300" />
          </div> */}
          {/* Email input */}
          <input
            type="email"
            placeholder="Email"
            className="border border-gray-300 rounded-lg px-4 py-2 mb-4 w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* Password input */}
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="border border-gray-300 rounded-lg px-4 py-2 mb-4 w-full pr-10"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* Toggle password visibility */}
            <button
              className="absolute top-0 right-0 mt-3 mr-4"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                /* Show eye icon when password is visible */
                <FaRegEye />
              ) : (
                /* Show crossed eye icon when password is hidden */
                <FaRegEyeSlash />
              )}
            </button>
          </div>
          {/* Sign in button */}
          <button
            onClick={handleLogin}
            className="bg-green-500 text-white rounded-lg px-4 py-2 w-full"
          >
            Sign In
          </button>
          {/* Link to sign up */}
          <p className="flex flex-wrap account-rel-text">
            Don't have an account?{" "}
            <Link to="/SignUp" className="font-medium">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
