import React from "react";
import CartContainer from "../components/CartContainer";
import { MdLocalGroceryStore } from "react-icons/md";
import NumberFormatExample from "../constant/NumberFormatExample";

const CartProductPin = ({
  visibilty,
  products,
  onProductRemove,
  onProductClear,
  onClose,
  onQuantityChange,
  qty,
  tot,
}) => {
  return (
    <div>
      <button
        onClick={() => onClose(!visibilty)}
        className="fixed top-[40%] z-40 -mt-12 hidden flex-col items-center justify-center rounded bg-green-600 p-3 pt-3.5 text-sm font-semibold text-white shadow-900 right-0 transition-colors duration-200 hover:bg-accent-hover focus:outline-0 lg:flex"
      >
        <span className="flex pb-0.5">
          <div className="flex ltr:ml-2 rtl:mr-2 items-center">
            <MdLocalGroceryStore size={20} />
            <div className="mx-1 text-base">{qty}</div>
            <div>Бараа</div>
          </div>
        </span>
        <span className="flex mt-3 w-full rounded bg-white px-2 py-2 text-teal-600 text-sm">
          {NumberFormatExample(tot)}₮
        </span>
      </button>
      {visibilty && (
        <CartContainer
          visibilty={visibilty}
          products={products}
          onClose={onClose}
          onProductClear={onProductClear}
          onProductRemove={onProductRemove}
          onQuantityChange={onQuantityChange}
          tot={tot}
        />
      )}
    </div>
  );
};

export default CartProductPin;
