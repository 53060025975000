import React from "react";
import Cover from "../images/kacc.jpg";

const About = () => {
  return (
    <div className="flex h-full">
      <div className=" w-full h-full pt-24 md:pt-24 ">
        <img
          alt=""
          loading="lazy"
          class="h-full max-h-[640px] w-full md:h-[550px]"
          decoding="async"
          data-nimg="1"
          src={Cover}
        />
        <div className="md:mx-44 md:mb-20 mx-12 mb-10">
          <div className="flex font-bold text-lg pt-10 justify-center">
            БИДНИЙ ТУХАЙ
          </div>
          <div className="flex justify-center">
            <div className="w-16 mt-5 border-b border-green-600"></div>
          </div>
          <div className="flex text-justify md:pt-10">
            Манай компани 2008 онд байгуулагдсан ба Кассын тоног төхөөрөмж
            худалдааны чиглэлээр үйл ажиллагаагаа тогтмол явуулж байгаа
            билээ.Бид Солонгос, Тайван,Хятад, Гонконг улсын үйлдвэрээс шууд
            оруулж ирдэг тул бусад ижил үйл ажиллагаа явуулдаг компаниудаас
            манай нийлүүлэх үнэ ямагт хямд, чанартай байдаг билээ. Бид одоогоор
            Улаанбаатар хотын ихэнх дэлгүүр, ресторан, эмийн сан гэх мэт кассын
            тоног төхөөрөмж хэрэглэдэг үйлчилгээний байгууллагад нийлүүлдэг.
            Одоогоор Улаанбаатар хотод 2 салбар нээн хэрэглэгчддээ ойрхон
            үйлчилгээ үзүүлэх, бараа захиалгыг онлайн хэлбэрээр авч хэрэглэгчдэд
            түргэн шуурхай хүргэж үйлчилж байна. Манай компани инженерүүдээ
            Гонконг явуулж сургалтанд хамруулсан бөгөөд цаашид БНСУ, Тайван руу
            сургалт явуулахаар төлөвлөн ажиллаж байгаа билээ Бид худалдан
            авагчдад сэтгэл ханамжийг авчрах нь бидний эрхэм зорилго билээ.
            Манай чадварлаг мэргэжилтнүүд танд хамгийн сүүлийн үеийн
            бүтээгдэхүүний талаарх мэдээлэлийг үйлчлүүлэгчдэд зөвлөгөө өгөх ,
            түүнчлэн та хамгийн сайн хэрэгцээнд тохирсон байдаг бүтээгдэхүүнийг
            сонгох шийдвэр гаргахад нь туслах болно.
          </div>
          <div className="font-bold py-3">Бидний уриа</div>
          <div className="flex font-bold justify-center">
            БИЗНЕС-ЭМХ ЦЭГЦ-АМЖИЛТ
          </div>
          <div className="font-bold py-3">Бидний онцлог</div>
          <div>
            <ul>
              <li>Энэ салбартаа тогтмол үйл ажиллагаа явуулж байгаа</li>
              <li>Кассын тоног төхөөрөмжийн сонголт олон</li>
              <li>
                Дэлхийд танигдсан чанартай брендийн бүтээгдэхүүнийг Монголд
                борлуулдаг
              </li>
              <li>Үнийн бодлого ямагт хамгийн хямд байдаг</li>
              <li>
                Сэлбэг хэрэгсэлийг үйлдвэрээс шууд нийлүүлэн засвар үйлчилгээ
                үзүүлдэг
              </li>
            </ul>
          </div>
          <div className="font-bold py-3">Үйл ажиллагаа чиглэл</div>
          <div>
            <ul>
              <li>Кассын тоног төхөөрөмж ба програм хангамж</li>
              <li>Рестораны тоног төхөөрөмжийн худалдаа ба засвар үйлчилгээ</li>
              <li>Програм хангамжийн үйлчилгээ</li>
              <li>Дэлгүүрийн хамгаалалтын хаалга</li>
              <li>Цаг бүртгэлийн систем</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
