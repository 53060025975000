import React from "react";

import Slider from "react-slick";
import jsData from "../data/product.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImgSwiper = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 10,
    slidesToScroll: 1,
    speed: 1500,
  };

  return (
    <div>
      <div className="hidden md:flex mt-10 justify-center pb-3 text-3xl">
        Брэндүүд
      </div>
      <div className="flex pb-3 justify-center">
        <div className="w-1/6   border-b border-green-600"></div>
      </div>
      <Slider
        {...settings}
        className="flex mx-36 mb-10 justify-center items-center  shadow-2xl "
      >
        {jsData.map((item) => (
          <div className="my-5">
            <img class="w-[100px] h-[100px]" src={item.imgs[3]} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImgSwiper;
