import React from "react";
import { useState, useEffect } from "react";
import jsData from "../data/product.json";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Tab from "../components/Tab";
import NumberFormatExample from "../constant/NumberFormatExample";
import "./ProductContainer";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { IoIosHeartEmpty, IoMdHeart } from "react-icons/io";
import { Alert } from "antd";
import secureLocalStorage from "react-secure-storage";
import { PostApi, GetApi, PutApi } from "../constant/Api";
import { APIURL } from "../context/SampleContext";
import { Rate } from "antd";
import Product from "../components/Product";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { productData, responsive } from "../data/data";

const ProductDetail = ({ addProductToCart, dBproduct, dBgroup }) => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [currentImage, setCurrentImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFav, setIsFav] = useState(false);

  // let APIURL = "127.0.0.1:8000";
  // let APIURL = "invoice.kacc.mn";
  const fetchProductById = async (productId) => {
    const token = "token ac6b32bfa0847e59640e363cbaec448e4321d510";
    const axiosInstance = axios.create({
      headers: {
        Authorization: `${token}`,
      },
    });

    try {
      const response = await axiosInstance.get(
        `https://${APIURL}/get_SiteProduct/${productId}/`
      );
      console.log(response.data.dtl);
      return response.data.dtl;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);

      try {
        // Fetch product data using the id from route parameters
        // Assuming you have a function like fetchProductById to fetch data
        const productData = await fetchProductById(id);
        console.log(productData);
        if (productData) {
          const {
            pk,
            catId,
            itemCode,
            name,
            imgs,
            price,
            discountPrice,
            qty,
            colors,
            tabInfo,
          } = productData[0];
          setCurrentImage(imgs[0]);
          setProduct({
            pk,
            catId,
            itemCode,
            name,
            imgs,
            price,
            discountPrice,
            qty,
            colors,
            tabInfo,
          });
          console.log(product);
        } else {
          console.error("Product data is not available");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProduct();
    scrollUp();
  }, [id]);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const defaultTab = product.tabInfo != null ? product.tabInfo[0] : "";

  const Loader = () => {
    let circleCommonClasses = "h-3 w-3 bg-current   rounded-full";

    return (
      <div className="flex">
        <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
        <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
        <div className={`${circleCommonClasses} animate-bounce400`}></div>
      </div>
    );
  };

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = secureLocalStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });
  const [upd, setUpd] = useState(0);

  const saveUserField = (product) => {
    console.log(userInfo);
    setUpd(0);

    if (userInfo != null && userInfo.TCWhishLists != null) {
      let updatedTCWhishLists;
      let isFav = false;

      // Check if the product is already in the wish list
      const isInWishList = userInfo.TCWhishLists.includes(product.pk);

      // Update TCWhishLists based on whether the product is in the wish list or not
      if (isInWishList) {
        updatedTCWhishLists = userInfo.TCWhishLists.filter(
          (item) => item !== product.pk
        );
      } else {
        updatedTCWhishLists = [...userInfo.TCWhishLists, product.pk];
        isFav = true;
      }

      // Update local storage and state
      secureLocalStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...userInfo,
          TCWhishLists: updatedTCWhishLists,
        })
      );

      setUserInfo((oldState) => ({
        ...oldState,
        TCWhishLists: updatedTCWhishLists,
      }));

      // Update isFav state
      setIsFav(isFav);

      PutApi(
        `put_EditUser/${userInfo.id}/`,
        JSON.stringify({
          ...userInfo,
          TCWhishLists: updatedTCWhishLists,
        })
      ).then((val) => {
        secureLocalStorage.setItem("userInfo", JSON.stringify(val.dtl));
      });
    } else {
      console.log("userInfo is null.");
    }
  };

  // var newUser = {};
  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  const productMore = (id) => {
    console.log(id);
    if (dBgroup) {
      const foundGroup = dBgroup.find((Gitem) => Gitem.catId === parseInt(id));

      console.log(dBgroup);
      if (foundGroup) {
        return dBproduct.map(
          (item) =>
            item.catId[0] == foundGroup.catId && (
              <Product
                key={item.id}
                addProductToCart={addProductToCart}
                item={item}
              />
            )
        );
      }
    } else {
      return null;
    }
  };

  const renderCarousel = (id) => {
    console.log(id);
    const dbCatProd = productMore(id);
    if (dbCatProd != null) {
      const products = productMore(id).filter((item) => item !== false);

      console.log(products);
      if (products && products.length > 0) {
        return (
          <Carousel
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
          >
            {products}
          </Carousel>
        );
      } else {
        return <div>Тухайн тасагт бараа бүртгэгдээгүй байна</div>;
      }
    } else {
      return <div>Тухайн тасагт бараа бүртгэгдээгүй байна</div>;
    }
  };
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="bg-white md:mt-9  ">
      {isLoading ? (
        <div className="flex h-screen items-center justify-center text-green-600">
          <div className="flex items-center justify-center">
            <div className="flex border-green-600 h-36 w-80 items-center justify-center text-2xl">
              <div>Түр хүлээнэ үү</div>
              <div className="mt-5 ml-3">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="rounded-lg bg-white">
          <div className="flex flex-col   md:flex-row">
            <div className="p-6 pt-20 md:mt-10 mt-5 md:w-1/2 lg:p-14 xl:p-16">
              <div className="hidden sm:block md:flex items-center justify-between mb-8 lg:mb-10">
                <a
                  href="#"
                  onClick={handleBackClick}
                  class="inline-flex items-center justify-center font-semibold text-sky-500 transition-colors hover:text-cyan-600 "
                >
                  <div className="px-2">
                    <AiOutlineArrowLeft />
                  </div>
                  Back
                </a>
              </div>
              <div class="block sm:hidden  mb-5 items-start justify-between w-full space-x-8 space-x-reverse">
                <h1 class="text-lg font-semibold tracking-tight  md:text-xl xl:text-2xl">
                  {product.name}
                </h1>
              </div>
              <div className="h-full relative">
                <div className="flex items-center justify-center border-2 md:ml-[65px] ">
                  <img
                    alt=""
                    loading="lazy"
                    className="w-[450px] md:h-[450px] md:object-cover h-fit"
                    decoding="async"
                    data-nimg="1"
                    src={currentImage}
                  />
                </div>
                {product.imgs?.length > 1 && (
                  <div className="flex max-w-full md:ml-[65px] ">
                    {product.imgs?.map(
                      (
                        img,
                        index // Added key prop to map iterator
                      ) => (
                        <img
                          key={index} // Added key prop
                          className="cursor-pointer max-h-24 border-2 hover:scale-110 transition-all mt-2 mr-2"
                          width={80}
                          height={80}
                          onClick={() => setCurrentImage(img)}
                          src={img}
                        />
                      )
                    )}
                  </div>
                )}
                {product.discountPrice !== 0 && (
                  <div className="absolute top-3 text-center bg-green-600 right-3 rounded bg-accent px-1.5 text-xs font-semibold leading-6 text-white sm:px-2 md:top-4 md:px-2.5">
                    {/* <div>Хямдралтай</div> */}
                    <div className="flex font-bold">
                      -
                      {NumberFormatExample(
                        product.price - product.discountPrice
                      )}
                      ₮
                    </div>
                  </div>
                )}
                {product.qty === 0 && (
                  <div className="absolute top-3 text-center bg-red-600 right-3 rounded bg-accent px-1.5 text-xs font-semibold leading-6 text-white sm:px-2 md:top-4 md:px-2.5">
                    {/* <div>Хямдралтай</div> */}
                    <div className="flex font-bold">Дууссан</div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:mt-10 items-start p-5 md:pt-10 md:w-1/2 lg:p-14 xl:p-16 md:mr-[30px]">
              <div class="w-full">
                <div class="hidden sm:block md:flex items-start justify-between w-full space-x-8 space-x-reverse mt-16">
                  <h1 class="text-lg font-semibold tracking-tight  md:text-xl xl:text-2xl">
                    {product.name}
                  </h1>

                  <div>
                    <button
                      type="button"
                      class=""
                      onClick={() => {
                        saveUserField(product);
                      }}
                    >
                      {isFav ? (
                        <IoMdHeart size={32} color="green" />
                      ) : (
                        <IoIosHeartEmpty size={32} color="green" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex items-center mt-2 justify-between">
                  <div className="flex">
                    <span className="block text-base font-medium mr-2">
                      Барааны код:
                    </span>
                    <span class="block text-base font-normal mr-5">
                      {product.itemCode}
                    </span>
                  </div>
                  {/* <div>
                    <button
                      type="button"
                      class=""
                      onClick={() => {
                        saveUserField(product);
                      }}
                    >
                      {isFav ? (
                        <IoMdHeart size={32} color="green" />
                      ) : (
                        <IoIosHeartEmpty size={32} color="green" />
                      )}
                    </button>
                  </div> */}
                </div>
                <div class="grid md:flex items-center mt-2">
                  <span className="block text-base font-medium mr-2 text-black">
                    Өнгө:
                  </span>
                  {product.colors?.length > 1 && (
                    <div class="flex ">
                      {product.colors?.map((color) =>
                        color === "black" ? (
                          <span
                            className={`border-2 border-green-600 drop-shadow-xl bg-black  
                       mt-0.5 mr-5 h-10 w-10 items-center  rounded-full`}
                          />
                        ) : (
                          <span
                            className={`border-2 border-green-600 drop-shadow-xl bg-${color}  
                     mt-0.5 mr-5 h-10 w-10 items-center  rounded-full`}
                          />
                        )
                      )}
                    </div>
                  )}
                  {/* <div className="mt-0.5 mr-10 h-10 w-10 items-center  rounded-full border border-gray-300"></div> */}
                  <span className="block text-base font-medium mr-2 ">
                    Үлдэгдэл:
                  </span>
                  <span class="block text-sm font-normal mr-5">
                    {product.qty}ш
                  </span>
                </div>
                {/* <div class="mt-3 text-sm leading-7  md:mt-4">
                  {product.tabInfo != null ? product.tabInfo[1].content : ""}
                </div> */}
                <div class="mt-3 text-sm leading-7  md:mt-4">
                  {product.tabInfo && product.tabInfo.length > 1 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.tabInfo[1].content,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <span class="flex items-center mt-5 md:mt-10">
                  {product.discountPrice === 0 ? (
                    <ins class="flex text-2xl font-semibold no-underline text-green-600 md:text-3xl">
                      {NumberFormatExample(product.price)}₮
                    </ins>
                  ) : (
                    <div className="flex items-end">
                      <ins className="flex text-base font-semibold text-end line-through text-gray-500 md:text-2xl">
                        {NumberFormatExample(product.price)}₮
                      </ins>
                      <ins className="flex text-2xl font-semibold no-underline text-green-600 md:text-3xl">
                        /
                      </ins>
                      <ins className="flex text-2xl font-semibold no-underline text-green-600 md:text-3xl">
                        {NumberFormatExample(product.discountPrice)}₮
                      </ins>
                    </div>
                  )}
                </span>
                {product.discountPrice !== 0 ? (
                  <div className="flex mt-2">
                    <div className="mr-3">Хэмнэлт: </div>
                    <div className="flex text-red-500 font-bold">
                      {NumberFormatExample(
                        product.price - product.discountPrice
                      )}
                      ₮
                    </div>
                  </div>
                ) : null}
                <div class="flex flex-col items-center mt-6 md:mt-6 lg:flex-row">
                  <div class="mb-3 w-full lg:mb-0 lg:max-w-[400px]">
                    <div>
                      <button
                        onClick={() => addProductToCart(product)}
                        class="flex w-full items-center justify-center rounded bg-green-600 py-4 px-5 text-sm font-white text-white transition-colors duration-300 hover:bg-teal-600 lg:text-base"
                      >
                        <span>Сагсанд нэмэх</span>
                      </button>
                    </div>
                  </div>
                  {/* <span class="text-base whitespace-nowrap  ml-10 ">
                  0 pieces available
                </span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mx-5">
            <Tab tabs={product.tabInfo} defaultTab={defaultTab} />

            <div className="border-b border-opacity-70 md:mx-32"></div>
            <div class="p-5 md:py-12 lg:px-16 md:ml-32">
              <h2 class="mb-7 text-lg font-semibold tracking-tight ">
                Ratings &amp; Reviews of {product.name}
              </h2>
              <Rate />
              <div>Нийт 0 хүн үнэлгээ өгсөн</div>
              {/* <div class="flex w-full flex-col divide-y divide-gray-200 divide-opacity-70 sm:flex-row sm:items-center sm:space-x-8 sm:divide-y-0 sm:divide-x ">
                <div class="w-full  pb-4 sm:w-auto sm:pb-0">
                  <span class="inline-flex shrink-0 items-center rounded-full bg-teal-500 text-white px-6 py-2 text-3xl font-semibold mb-4">
                    0
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 25.056 24"
                      class="h-6 w-6 ml-2 mr-2"
                    >
                      <g data-name="Group 36413" fill="currentColor">
                        <path
                          id="Path_22667"
                          data-name="Path 22667"
                          d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                          transform="translate(0 -10.792)"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  <p class="text-base text-gray-400">
                    <span>0 ratings</span>
                  </p>
                </div>
                <div class="space-y-3 py-0.5 pt-4 pl-5 sm:pt-0 ">
                  <div></div>
                  <div class="flex items-center text-sm ">
                    <div class="flex w-11 shrink-0 items-center space-x-1 font-semibold space-x-reverse">
                      <span class="text-sm font-semibold ">5</span>{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25.056 24"
                        class="h-2.5 w-2.5 ml-1.5 mr-1.5"
                      >
                        <g data-name="Group 36413" fill="currentColor">
                          <path
                            id="Path_22667"
                            data-name="Path 22667"
                            d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                            transform="translate(0 -10.792)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div class="relative h-[5px] w-52 overflow-hidden rounded-md bg-[#F1F1F1]">
                      <div class="absolute h-full rounded-md bg-teal-500"></div>
                    </div>
                    <div class="shrink-0 pl-5 pr-5">0</div>
                  </div>
                  <div class="flex items-center text-sm ">
                    <div class="flex w-11 shrink-0 items-center space-x-1 font-semibold space-x-reverse">
                      <span class="text-sm font-semibold ">4</span>{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25.056 24"
                        class="h-2.5 w-2.5 ml-1.5 mr-1.5"
                      >
                        <g data-name="Group 36413" fill="currentColor">
                          <path
                            id="Path_22667"
                            data-name="Path 22667"
                            d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                            transform="translate(0 -10.792)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div class="relative h-[5px] w-52 overflow-hidden rounded-md bg-[#F1F1F1]">
                      <div class="absolute h-full rounded-md bg-green-600"></div>
                    </div>
                    <div class="shrink-0 pl-5 pr-5">0</div>
                  </div>
                  <div class="flex items-center text-sm ">
                    <div class="flex w-11 shrink-0 items-center space-x-1 font-semibold space-x-reverse">
                      <span class="text-sm font-semibold ">3</span>{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25.056 24"
                        class="h-2.5 w-2.5 ml-1.5 mr-1.5"
                      >
                        <g data-name="Group 36413" fill="currentColor">
                          <path
                            id="Path_22667"
                            data-name="Path 22667"
                            d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                            transform="translate(0 -10.792)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div class="relative h-[5px] w-52 overflow-hidden rounded-md bg-[#F1F1F1]">
                      <div class="absolute h-full rounded-md bg-teal-400"></div>
                    </div>
                    <div class="shrink-0 pl-5 pr-5">0</div>
                  </div>
                  <div class="flex items-center text-sm ">
                    <div class="flex w-11 shrink-0 items-center space-x-1 font-semibold space-x-reverse">
                      <span class="text-sm font-semibold ">2</span>{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25.056 24"
                        class="h-2.5 w-2.5 ml-1.5 mr-1.5"
                      >
                        <g data-name="Group 36413" fill="currentColor">
                          <path
                            id="Path_22667"
                            data-name="Path 22667"
                            d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                            transform="translate(0 -10.792)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div class="relative h-[5px] w-52 overflow-hidden rounded-md bg-[#F1F1F1]">
                      <div class="absolute h-full rounded-md bg-amber-500"></div>
                    </div>
                    <div class="shrink-0 pl-5 pr-5">0</div>
                  </div>
                  <div class="flex items-center text-sm ">
                    <div class="flex w-11 shrink-0 items-center space-x-1 font-semibold space-x-reverse">
                      <span class="text-sm font-semibold ">1</span>{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25.056 24"
                        class="h-2.5 w-2.5 ml-1.5 mr-1.5"
                      >
                        <g data-name="Group 36413" fill="currentColor">
                          <path
                            id="Path_22667"
                            data-name="Path 22667"
                            d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                            transform="translate(0 -10.792)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <div class="relative h-[5px] w-52 overflow-hidden rounded-md bg-[#F1F1F1]">
                      <div class="absolute h-full rounded-md bg-rose-500"></div>
                    </div>
                    <div class="shrink-0 pl-5 pr-5">0</div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            {product.catId && (
              <div className="md:mx-32 mx-5 md:mb-10 mb-5">
                <div className="flex justify-between md:pt-20">
                  <div className="flex  justify-left items-end md:pb-3 md:text-3xl text-sm font-semibold text-green-600">
                    ТӨСТЭЙ БАРААНУУД
                  </div>

                  <div className="pt-2.5 pl-5 md:text-lg text-sm  hover:text-green-600">
                    <Link
                      to={{
                        pathname: `/ProductCat/${product.catId[0]}/${0}`,
                        // state: { productData: Gitem },
                      }}
                    >
                      ...Бүгдийг харах
                    </Link>
                  </div>
                </div>
                <div className="flex pb-8  justify-left">
                  <div className="w-full   border-b border-green-600"></div>
                </div>
                {renderCarousel(product.catId[0])}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
