import React, { useState, useEffect } from "react";
import Img from "../images/form_img1.jpg";
import { Col, Row } from "antd";
import GoogleLoginBtn from "../components/GoogleLogin";
import { Link, useLocation } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { PostApi, GetApi } from "../constant/Api";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const SignUp = () => {
  const navigate = useNavigate();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [pPassword, setPPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLogin, setIsLogin] = useState(true);
  const [isSignUp, setSignUp] = useState(true);
  const handleClick = (isBtn) => {
    isBtn ? setIsLogin(!isLogin) : setSignUp(!isSignUp);
  };

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = secureLocalStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : {};
  });

  useEffect(() => {
    secureLocalStorage.setItem("userInfo", JSON.stringify(userInfo));
  }, [userInfo]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserInfo({ ...userInfo, [name]: value });
  // };

  const clientId =
    "932737388320-ltbl50cuo9hgvag198hpnvdrqtc3a23r.apps.googleusercontent.com";

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
      gapi.load("client:auth2", start);
    }
  });

  const handleLogin = () => {
    // console.log(userInfo);

    const data = {
      TCUserName: lName,
      TCEmail: email,
      TCPhoneNumber: phone,
      TCPassword: password,
    };

    const userDJson = JSON.stringify(data);
    PostApi("post_CreateUser/", userDJson).then((val) => {
      if (val.statusCode == 200) {
        setUserInfo(val.dtl);
        navigate("/#");
        window.location.reload();
      }

      console.log("Logging in with:", userInfo);
    });
  };

  // Function to update user information
  const updateUser = (newUserInfo) => {
    setUserInfo(newUserInfo);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "lName":
        setLName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "pPassword":
        setPPassword(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex mt-60 mx-72 mb-28  justify-center items-center ">
      <div className="grid grid-cols-2 h-96 shadow-lg min-h-screen">
        <div className="overflow-hidden relative">
          <img
            src={Img}
            alt="Your image"
            className="object-cover w-full h-full "
          />
        </div>
        <div className="grid w-full content-center p-6">
          <div className="flex justify-start mb-6">
            <div>
              <h2 className="text-lg font-bold ">Бүртгүүлэх</h2>
            </div>
          </div>

          {/* Email input */}
          <input
            type="text"
            placeholder="TCUserName"
            className="border border-gray-300 rounded-lg px-4 py-2 mb-4 w-full"
            value={lName}
            name="lName"
            onChange={handleInputChange}
          />
          <input
            type="email"
            placeholder="Email"
            className="border border-gray-300 rounded-lg px-4 py-2 mb-4 w-full"
            value={email}
            name="email"
            onChange={handleInputChange}
          />
          <input
            type="text"
            placeholder="TCPhoneNumber"
            className="border border-gray-300 rounded-lg px-4 py-2 mb-4 w-full"
            value={phone}
            name="phone"
            onChange={handleInputChange}
          />
          {/* Password input */}
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="border border-gray-300 rounded-lg px-4 py-2 mb-4 w-full pr-10"
              value={password}
              name="password"
              onChange={handleInputChange}
            />
            {/* Toggle password visibility */}
            <button
              className="absolute top-0 right-0 mt-3 mr-4"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                /* Show eye icon when password is visible */
                <FaRegEye />
              ) : (
                /* Show crossed eye icon when password is hidden */
                <FaRegEyeSlash />
              )}
            </button>
          </div>
          {/* PPassword input */}
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="border border-gray-300 rounded-lg px-4 py-2 mb-4 w-full pr-10"
              value={pPassword}
              name="pPassword"
              onChange={(e) => setPPassword(e.target.value)}
            />
            {/* Toggle password visibility */}
            <button
              className="absolute top-0 right-0 mt-3 mr-4"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                /* Show eye icon when password is visible */
                <FaRegEye />
              ) : (
                /* Show crossed eye icon when password is hidden */
                <FaRegEyeSlash />
              )}
            </button>
          </div>
          {/* Sign in button */}
          <button
            onClick={handleLogin}
            className="bg-green-500 text-white rounded-lg px-4 py-2 w-full"
          >
            Sign Up
          </button>
          {/* Link to sign up */}
          <p className="flex flex-wrap account-rel-text">
            Don't have an account?{" "}
            <Link to="/sign_up" className="font-medium">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
