import React, { useState, useEffect } from "react";
import NumberFormatExample from "../constant/NumberFormatExample";
import { PostApi, GetApi, PutApi } from "../constant/Api";
import secureLocalStorage from "react-secure-storage";
import { Link, useNavigate } from "react-router-dom";
import { successToast, errorToast } from "../constant/ReacrToast";
import AddressModal from "./layout/AddressModal";

const OrderAddress = ({ products, tot, dBproduct }) => {
  const navigate = useNavigate();
  const [open, setopen] = useState(false);
  const [check, setchecked] = useState(true);
  const clickedcheck = () => {
    setchecked(!check);
  };
  const [isCompany, setIsCompany] = useState(false);
  const clickedEbarimt = () => {
    setIsCompany(!isCompany);
  };
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [activeDiv, setActiveDiv] = useState(null);

  const handleClick = (divId) => {
    setActiveDiv(divId === activeDiv ? null : divId);
    console.log(`handleClick = ${divId}`);
  };

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = secureLocalStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const [userAddress, setUserAddress] = useState([]);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    GetApi(`get_UserAddress/${userInfo.id}/`).then((val) => {
      setUserAddress(val.dtl);
    });
    GetApi(`get_locations/`).then((val) => {
      setLocations(val.dtl);
    });
  }, []);

  const addressRemove = () => {};
  const [isAddress, setIsAddress] = useState(0);
  const clickedAddress = (id) => {
    setIsAddress((prevAddress) => {
      console.log(isAddress);
      return prevAddress === id ? 0 : id;
    });
  };

  const payMents = () => {
    if (isAddress != 0) {
      navigate(`/Payments`, { state: { isAddress: isAddress } });
    } else {
      errorToast("Хүргэлтээр авах хаягаа сонгоно уу!");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressPk, setAddressPk] = useState(0);

  const closeModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    // setModalData({ companyName, companyRegister, companyPhone, companyMail });
  };

  const editAddress = (pk, isOpen) => {
    console.log(pk);
    setAddressPk(pk);
    setIsModalOpen(isOpen);
  };

  return (
    <div className="mt-40 mx-4 lg:mt-40 lg:mx-36">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <h5 className="font-bold text-xl ml-6">Захиалагчийн мэдээлэл</h5>
          <div className="p-4 m-6 bg-white rounded-lg shadow-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="m-2">
                <h5>Нэр:</h5>
                <input
                  type="search"
                  id="default-search"
                  className="flex-1 w-full p-4 text-sm rounded-lg bg-white text-black border-2 border-slate-400"
                  placeholder="Нэр"
                  value={userInfo.TCUserName}
                  name="TCUserName"
                  onChange={handleInputChange}
                />
              </div>
              <div className="m-2">
                <h5>Овог:</h5>
                <input
                  type="search"
                  id="default-search"
                  className="flex-1 w-full p-4 text-sm rounded-lg bg-white text-black border-2 border-slate-400"
                  placeholder="Овог"
                  value={userInfo.TCUserName}
                  name="TCUserName"
                  onChange={handleInputChange}
                />
              </div>
              <div className="m-2">
                <h5>Утасны дугаар:</h5>
                <input
                  type="search"
                  id="default-search"
                  className="flex-1 w-full p-4 text-sm rounded-lg bg-white text-black border-2 border-slate-400"
                  placeholder="Утасны дугаар"
                  value={userInfo.TCPhoneNumber}
                  name="TCPhoneNumber"
                  onChange={handleInputChange}
                />
              </div>
              <div className="m-2">
                <h5>И-Мэйл хаяг:</h5>
                <input
                  type="search"
                  className="flex-1 w-full p-4 text-sm rounded-lg bg-white text-black border-2 border-slate-400"
                  placeholder="И-Мэйл хаяг"
                  value={userInfo.TCEmail}
                  name="TCEmail"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="m-2">
              <div className="bg-gray-200 w-full p-4 mt-3 rounded-lg">
                <input
                  value={isCompany}
                  defaultChecked={false}
                  onChange={clickedEbarimt}
                  type="checkbox"
                />
                И-баримт албан байгууллагаар авах
              </div>
            </div>
          </div>

          <div>
            <h5 className="ml-6 font-bold text-xl">Хүргэлтийн хаяг</h5>
            {userAddress != null ? (
              userAddress.map((item) => (
                <div className="border rounded-xl p-5 my-5 mx-6 ">
                  <div className="">
                    <div className="text-lg font-semibold pb-3">
                      {(() => {
                        const filteredLocation = locations.find(
                          (location) => location.id === item.TCCityLocation
                        );
                        return filteredLocation ? filteredLocation.name : null;
                      })()}
                    </div>
                    <div className="text-xs pb-2">{item.TCDetailAddress}</div>
                    <div className="flex pb-3">
                      <div className="bg-gray-100 rounded mr-3 p-1">
                        {item.TCAddressName}
                      </div>
                      <div className="bg-gray-100 rounded p-1">
                        <a
                          href={item.TCGoogleMapUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Google Map Линк
                        </a>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div>
                        <button
                          type="button"
                          className="text-black"
                          onClick={() => {
                            addressRemove();
                          }}
                        >
                          Remove
                        </button>
                      </div>
                      <div className="w-0.5 h-5 bg-gray-500 mx-2 rounded-md"></div>
                      <div>
                        <button
                          onClick={() => editAddress(item.id, true)}
                          type="button"
                          className="text-black"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center pt-2">
                    <input
                      checked={isAddress === item.id}
                      defaultChecked={false}
                      onChange={() => clickedAddress(item.id)}
                      type="checkbox"
                    />
                    <div className="pl-2 text-sm text-red-700">
                      Хүргэх хаягаа сонгоно уу?
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div> </div>
            )}
          </div>
        </div>

        <AddressModal
          isOpen={isModalOpen}
          onClose={closeModal}
          addressPk={addressPk}
        />
        <div className="mx-6">
          <h5 className="font-bold text-xl ">Төлбөрийн мэдээлэл</h5>
          <div className="border-2 mt-5 rounded-xl shadow-sm p-3">
            <div className="font-semibold border-b pb-2">Бүтээгдэхүүн</div>
            {products.map((item) => (
              <div className="flex justify-between pt-2">
                <div className="flex justify-between w-full md:w-80">
                  <div className="flex text-gray-500">{item.name}</div>
                  <div className="text-gray-500 border rounded-md w-6 text-center bg-slate-200">
                    x{item.count}
                  </div>
                </div>
                <div className="flex pl-10 font-medium">
                  {item.discountPrice === 0
                    ? NumberFormatExample(parseFloat(item?.price) * item?.count)
                    : NumberFormatExample(
                        parseFloat(item?.discountPrice) * item?.count
                      )}
                  ₮
                </div>
              </div>
            ))}
            <div>
              <div className="border-t my-3" />
              <div className="flex justify-between">
                <div className="font-semibold">Нийт төлөх дүн</div>
                <div className="flex pl-10 font-bold">
                  {NumberFormatExample(tot)}₮
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1">
            <button
              onClick={() => payMents()}
              className="border-2 mt-5 w-full rounded-xl shadow-sm p-3 bg-green-600 text-center text-white font-semibold"
            >
              Үргэлжлүүлэх
            </button>
            <button
              onClick={() => {
                console.log(isAddress);
              }}
              className="bg-white text-black border-2 border-slate-400 p-3 mt-4 rounded-lg"
            >
              Өмнөх алхам руу буцах
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderAddress;
