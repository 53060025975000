import React, { useState } from "react";
import { AiOutlineUnorderedList, AiOutlineDownload } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";

const Tab = ({ tabs, defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [isLoading, setIsLoading] = useState(false);
  const [dtlDrivers, setDtlDrivers] = useState([]);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="">
      <div className="px-5 py-4 md:py-6  border border-opacity-70  md:mx-32 ">
        <div className="grid grid-flow-col auto-cols-max md:justify-center justify-evenly  md:font-medium md:text-xl text-gray-600">
          {tabs != null
            ? tabs.map((tab) => (
                <h2
                  key={tab.title}
                  onClick={() => changeTab(tab)}
                  className={`${
                    tab.title === "Үзүүлэлт"
                      ? "border-x border-opacity-70 md:mx-12 md:px-12 px-3"
                      : ""
                  } hover:text-green-600 cursor-pointer`}
                >
                  {tab.title}
                </h2>
              ))
            : ""}
        </div>
      </div>
      <div className="md:mx-32">
        <div className="md:p-10 p-3 ">
          <h1 className="text-lg font-semibold">{activeTab.title}</h1>
          <div>
            {activeTab.title === "Татах" ? (
              activeTab.content != null && activeTab.content.length > 0 ? (
                activeTab.content.map((dtl) => (
                  <div
                    key={dtl.pk}
                    className="flex border-b mt-2 justify-between mr-32 py-2"
                  >
                    <div>{dtl.TCFileName}</div>
                    <button
                      className="flex items-center justify-evenly bg-green-600 rounded-md w-20 text-white"
                      onClick={() => handleClick(dtl.TCFileUrl)}
                    >
                      <HiDownload />
                      <div>Татах</div>
                    </button>
                  </div>
                ))
              ) : (
                <div>Холбоос олдсонгүй</div>
              )
            ) : (
              <span>
                {/* {activeTab.content} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: activeTab.content,
                  }}
                />
              </span>
            )}
          </div>
          {/* <div className="md:w-full md:ml-12 md:mx-0 mx-10">
            <div>
              <div className="flex text-lg items-center ">
                <AiOutlineDownload className="fill-green-600 mr-3" size={30} />
                <div>Холбоосууд</div>
              </div>
              {isLoading ? (
                <div>Loading...</div>
              ) : dtlDrivers.length > 0 ? (
                dtlDrivers.map((dtl) => (
                  <div
                    key={dtl.dId}
                    className="flex border-b mt-2 justify-between mr-32 py-2"
                  >
                    <div>{dtl.dUrl}</div>
                    <button className="flex items-center justify-evenly bg-green-600 rounded-md w-20 text-white">
                      <HiDownload />
                      <div>Татах</div>
                    </button>
                  </div>
                ))
              ) : (
                ""
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Tab;
