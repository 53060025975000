import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { PostApi, GetApi, PutApi } from "../constant/Api";
import NumberFormatExample from "../constant/NumberFormatExample";
import { Link } from "react-router-dom";
import { Card } from "antd";
import icon from "../images/Logo.png";
import PayCheck from "./layout/PayCheck";
import axios from "axios";
import { successToast, errorToast } from "../constant/ReacrToast";
import { APIURL } from "../context/SampleContext";
import { useNavigate } from "react-router-dom";

const Bank = ({ products, tot, dBproduct, onProductClear }) => {
  const navigate = useNavigate();
  const [orderNum, setOrderNum] = useState(() => {
    const orderNum = secureLocalStorage.getItem("orderNum");
    return orderNum ? JSON.parse(orderNum) : null;
  });

  const GetPay = async () => {
    try {
      GetApi(`api/CheckOrder/?TCOrderNumber=${orderNum}&TCTotalAmount=${tot}`)
        .then((val) => {
          if (val.dtl === "Order Confirm") {
            successToast("Төлбөр төлөгдсөн");
            navigate(`/OrderHistory`);
            secureLocalStorage.removeItem("orderNum");
            onProductClear();
          } else {
            errorToast("Төлбөр төлөгдөөгүй байна!");
          }
        })
        .catch((error) => {
          errorToast(error);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    scrollUp();
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className="mt-20 md:mx-4 lg:mt-40 lg:mx-36">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 text-lg">
        <div className="lg:col-span-2 m-6">
          <h5 className="font-bold text-xl mx-4">Дансаар шилжүүлэх</h5>
          <div className="grid w-full text-start">
            <div className="flex pt-5 w-full justify-center">
              <Card
                className="bg-gray-200 text-center text-lg"
                title={
                  <div className="text-txt-color">
                    Захиалгын дугаар:{" "}
                    <strong className="text-red-500 text-lg">{orderNum}</strong>
                  </div>
                }
                bordered={true}
                style={{
                  width: "90%",
                  backgroundColor: "#F4F6FA",
                }}
              >
                <p className="text-txt-color">Банкны нэр: Голомт Банк</p>
                <p className="text-txt-color">Хүлээн авагч: КАСС МОЛЛ ХХК</p>
                <p className="text-txt-color">Дансны дугаар: 3405125869</p>
                <div className="flex justify-center text-txt-color">
                  <p>Шилжүүлэх дүн: </p>
                  <p className="text-txt-color"> {NumberFormatExample(tot)}</p>
                  <p className="text-txt-color">₮</p>
                </div>
                <p className="text-txt-color">
                  Гүйлгээний утга:{" "}
                  <strong className="text-red-500">{orderNum}</strong>
                </p>
              </Card>
            </div>
            <div className="bg-green-400 border rounded-md p-3 md:m-10 m-4">
              <div className="text-sm">
                • Төлбөр төлсөн бол Төлбөр шалгах товчийг дарна уу.
              </div>
              <div className="text-sm">
                • Гүйлгээний утга дээр зөвхөн <strong>{orderNum}</strong>{" "}
                дугаарыг бичсэнээр төлбөр баталгаажна.
              </div>
              <div className="text-sm">
                • Танд ямар нэг асуух зүйл байгаа бол манай 7766-9002 утсаар
                хологдоно уу.
              </div>
            </div>
          </div>
        </div>
        <div className="m-6">
          <h5 className="font-bold lg:text-base xl:text-xl">
            Төлбөрийн мэдээлэл
          </h5>
          <div className="border-2 mt-5 rounded-xl shadow-sm p-3">
            <div className="font-semibold lg:text-base xl:text-xl border-b pb-2">
              Бүтээгдэхүүн
            </div>
            {products.map((item) => (
              <div className="flex justify-between pt-2">
                <div className="flex justify-between w-full md:w-80">
                  <div className="text-gray-500 lg:text-base xl:text-xl">
                    {item.name}
                  </div>
                  <div className="text-gray-500 border rounded-md w-6 text-center lg:text-base xl:text-xl bg-slate-200">
                    x{item.count}
                  </div>
                </div>
                <div className="flex xl:pl-10 lg:pl-5 pl-5 font-medium lg:text-base xl:text-xl">
                  {item.discountPrice === 0
                    ? NumberFormatExample(parseFloat(item.price) * item.count)
                    : NumberFormatExample(
                        parseFloat(item.discountPrice) * item.count
                      )}
                  ₮
                </div>
              </div>
            ))}
            <div className="border-t my-3"></div>
            <div className="lg:grid lg:justify-center flex justify-between">
              <div className="font-semibold lg:text-base xl:text-xl">
                Нийт төлөх дүн
              </div>
              <div className="flex xl:pl-10 pl-5 font-bold lg:text-base xl:text-xl">
                {NumberFormatExample(tot)}₮
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1">
            <button
              onClick={() => GetPay()}
              className=" bg-green-600 text-white  p-3 mt-4 rounded-lg "
            >
              {/* <Link to="/">Төлбөр шалгах</Link> */}
              Төлбөр шалгах
            </button>
            <button className="bg-white text-black border-2 border-slate-400 p-3 mt-4 rounded-lg">
              Өмнөх алхам руу буцах
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bank;
