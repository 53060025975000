import React from "react";
import Aravt from "../images/Aravt.jpg";
import Smart from "../images/SmartLogic.jpg";
import Dayan from "../images/Dayansoft.png";
import Masu from "../images/Masu.jpg";
import { Button } from "@material-tailwind/react";

const ProgrammScreen = () => {
  return (
    <div className="md:mt-40  mt-24 md:mx-0 mx-8 h-full">
      <div className="flex font-bold md:text-4xl text-lg justify-center">
        Хамтрагч программ хангамжууд
      </div>
      <div className="flex justify-center">
        <div className="w-1/5 md:mt-10 my-4 border-b border-green-600"></div>
      </div>
      <div className="md:mx-44 md:mt-10 md:mb-32">
        <ul className="grid md:grid-cols-2 gap-4">
          <li className="bg-white shadow-lg border rounded-3xl border-transparent">
            <div className="p-5 ">
              <img
                alt=""
                loading="lazy"
                class="h-full max-h-[340px] w-full md:h-[550px] border rounded-lg shadow-lg"
                decoding="async"
                data-nimg="1"
                src={Aravt}
              />
              <div className="font-semibold text-lg pt-5 px-5">
                Аравт Программ
              </div>
              <div className="pt-3 px-5">
                Бүх төрлийн худалдаа үйлчилгээ явуулдаг аж ахуй нэгж, хувь хүн
                ашиглах боломжтой кассын програм юм.
              </div>
              <div className="flex px-5">
                <div className="font-semibold mr-1">Холбоо барих:</div>
                <div>9999-0623, 94043098</div>
              </div>
              <div className="pt-5 pl-5">
                <a href="http://aravt.mn" target="_blank">
                  <Button className="bg-green-600 hover:scale-110 ">
                    Site-аар зочлох
                  </Button>
                </a>
              </div>
            </div>
          </li>
          <li className="bg-white shadow-lg border rounded-3xl border-transparent">
            <div className="p-5 ">
              <img
                alt=""
                loading="lazy"
                class="h-full max-h-[340px] w-full md:h-[550px] border rounded-lg shadow-lg"
                decoding="async"
                data-nimg="1"
                src={Smart}
              />
              <div className="font-semibold text-lg pt-5 px-5">
                SmartLogic Программ
              </div>
              <div className="pt-3 px-5">
                Смарт Ложик ХХК нь програм хангамжийн компани бөгөөд бизнесийн
                байгууллагуудад зориулсан ERP - систем
              </div>
              <div className="flex px-5">
                <div className="font-semibold mr-1">Холбоо барих:</div>
                <div>77019090-3</div>
              </div>
              <div className="pt-5 pl-5">
                <a href="https://www.smartlogic.mn" target="_blank">
                  <Button className="bg-green-600 hover:scale-110 ">
                    Site-аар зочлох
                  </Button>
                </a>
              </div>
            </div>
          </li>
          <li className="bg-white shadow-lg border rounded-3xl border-transparent">
            <div className="p-5 ">
              <img
                alt=""
                loading="lazy"
                class="h-full max-h-[340px] w-full md:h-[550px] border rounded-lg shadow-lg"
                decoding="async"
                data-nimg="1"
                src={Dayan}
              />
              <div className="font-semibold text-lg pt-5 px-5">
                Dayansoft Программ
              </div>
              <div className="pt-3 px-5">
                Өдөр тутам 700+ бизнест ашиглаж байгаа хэрэглэхэд хялбар, нүсэр
                биш ч боломж ихтэй, шинэлэг, санхүү бүртгэл, бизнес менежментийн
                цогц ERP систем
              </div>
              <div className="flex px-5">
                <div className="font-semibold mr-1">Холбоо барих:</div>
                <div>7222-3333-2</div>
              </div>
              <div className="pt-5 pl-5">
                <a href="https://www.dayansoft.mn" target="_blank">
                  <Button className="bg-green-600 hover:scale-110 ">
                    Site-аар зочлох
                  </Button>
                </a>
              </div>
            </div>
          </li>
          <li className="bg-white shadow-lg border rounded-3xl border-transparent">
            <div className="p-5 ">
              <img
                alt=""
                loading="lazy"
                class="h-full max-h-[340px] w-full md:h-[550px] border rounded-lg shadow-lg"
                decoding="async"
                data-nimg="1"
                src={Masu}
              />
              <div className="font-semibold text-lg pt-5 px-5">
                Masu Программ
              </div>
              <div className="pt-3 px-5">
                Ухаалаг утас , таблет төхөөрөмжиндөө ПОС програм үнэгүйгээр
                суулгах боломжтой бөгөөд борлуулалт, бараа материал болон
                ажилчдаа илүү үр дүнтэйгээр удирдах боломжийг олгоно. Мөн
                түүнчлэн дэлгүүрийн тооноос үл хамааран програм хангамжаар
                дэлгүүр болон борлуулалтын цэгүүдийг удирдах боломжийг танд
                олгоно.
              </div>
              <div className="flex px-5">
                <div className="font-semibold mr-1">Холбоо барих:</div>
                <div>7709-2288, 9910-2115</div>
              </div>
              <div className="pt-5 pl-5">
                <a href="https://masu.mn" target="_blank">
                  <Button className="bg-green-600 hover:scale-110 ">
                    Site-аар зочлох
                  </Button>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProgrammScreen;
