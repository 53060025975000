import React, { useState, useEffect } from "react";
import axios from "axios";
import productJson from "../data/product.json";
import { PiDotOutlineFill } from "react-icons/pi";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import ProductContainer from "./ProductContainer";
import gGroups from "../data/category.json";

import { Url } from "../context/SampleContext";
import { useLocation } from "react-router-dom";

const SearchProductCat = ({ addProductToCart, dBproduct, dBgroup, isMain }) => {
  const [catId, setCatId] = useState("");
  const [catDtlId, setCatDtlId] = useState("");

  const [isOpenLan, setIsOpenLan] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  React.useEffect(() => {
    setShowMenuGroup(dBgroup);
    const query = new URLSearchParams(location.search);
    setSearchQuery(query.get("query"));
    const filtered = dBproduct.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    console.log(filteredData);
  }, [location, searchQuery, dBproduct]);

  useEffect(() => {
    scrollUp();
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const Loader = () => {
    setIsLoading(true);
    let circleCommonClasses = "h-3 w-3 bg-current rounded-full";

    return (
      <div className="flex">
        <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
        <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
        <div className={`${circleCommonClasses} animate-bounce400`}></div>
      </div>
    );
  };

  const [showMenuGroup, setShowMenuGroup] = useState([]);

  const [counter, setCounter] = useState(0);
  const handleButtonClick = (catId) => {
    let updatedDBGroup = dBgroup;

    updatedDBGroup = dBgroup.map((item) => ({
      ...item,
      isShowMenu: false,
    }));
    if (counter !== catId) {
      updatedDBGroup = dBgroup.map((item) => ({
        ...item,
        isShowMenu: item.catId === catId ? true : item.isShowMenu,
      }));
      setCounter(catId);
    } else {
      setCounter(0);
    }
    setShowMenuGroup(updatedDBGroup);
  };

  return (
    <div className="grid grid-cols-4 my-40 mx-24 ">
      <div className="col-span-1 border mx-5 p-5">
        <div className="text-lg font-bold mb-3 border-b pb-1 text-gray-500">
          Ангилал
        </div>
        <div className="">
          {showMenuGroup.map((category) => (
            <div key={category.catId}>
              <div
                className={`text-base text-gray-500 pt-3 font-semibold${
                  catId == category.catId ? "font-semibold text-green-600" : ""
                }`}
              >
                {/* {
                  (category.isShowMenu = true
                    ? (category.isShowMenu = false)
                    : null)
                } */}
                <a
                  href={`/ProductCat/${category.catId}/0`}
                  className="flex justify-between"
                >
                  <div>
                    <button
                      className="focus:outline-none"
                      onClick={() => handleButtonClick(category.catId)}
                    >
                      {category.catName}
                    </button>
                  </div>
                  <button
                    className="focus:outline-none"
                    onClick={() => handleButtonClick(category.catId)}
                  >
                    {category.isShowMenu ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 15.75 7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                  </button>
                </a>
              </div>
              <ul>
                {category.isShowMenu
                  ? Object.entries(category.catDtl[0]).map(
                      ([key, value], index) => (
                        <div>
                          <li className="flex text-gray-500" key={index}>
                            <div className="pl-5 mr-3">-</div>
                            <a
                              href={`/ProductCat/${category.catId}/${key}`}
                              className={`${
                                catDtlId == key && catId == category.catId
                                  ? "font-semibold text-green-600"
                                  : "hover:text-green-600"
                              }`}
                            >
                              {value}
                            </a>
                          </li>
                          <div className="mx-5 border-b" />
                        </div>
                      )
                    )
                  : null}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="col-span-3 py-5">
        <div className="flex justify-between px-5">
          <div className="flex items-end">
            <div className="flex">
              {dBgroup.map((category) =>
                catDtlId != 0
                  ? Object.values(category.catDtl[0]).map((detail, index) => (
                      <div className="font-semibold">
                        {catDtlId == index + 1 && catId == category.catId
                          ? detail
                          : ""}
                      </div>
                    ))
                  : category.catId == catId
                  ? category.catName
                  : ""
              )}
              <div className="flex text-gray-500 ml-3 text-xs items-center">
                <div className="mr-2">
                  {filteredData && filteredData.length}
                </div>
                <div>бүтээгдэхүүн</div>
              </div>
            </div>
          </div>

          <div>
            <button
              onClick={() => setIsOpenLan(!isOpenLan)}
              className="flex items-center justify-between border px-3 py-2"
            >
              <div className="text-xs mr-5">Шинэ нь эхэндээ</div>
              <div>
                {isOpenLan ? (
                  <AiOutlineUp size={14} className="fill-green-600" />
                ) : (
                  <AiOutlineDown size={14} className="fill-green-600" />
                )}
              </div>
            </button>
          </div>
        </div>
        <div className="border-b-2 mx-7 pt-3"></div>
        <div className="my-5">
          {isLoading ? (
            <div className="flex h-screen items-center justify-center text-green-600">
              <div className="flex items-center justify-center">
                <div className="flex border-green-600 h-36 w-80 items-center justify-center text-2xl">
                  <div>Түр хүлээнэ үү </div>
                  <div className="mt-5 ml-3">
                    <Loader />
                  </div>
                </div>
              </div>
            </div>
          ) : filteredData.length > 0 ? (
            <ProductContainer
              addProductToCart={addProductToCart}
              dBproduct={filteredData}
              isMain={false}
            />
          ) : (
            <div className="flex h-screen items-center justify-center text-green-600">
              <div className="flex items-center justify-center">
                <div className="flex border-green-600 h-36 w-80 items-center justify-center text-2xl">
                  <div>Уучлаарай бүртгэлтэй бараа байхгүй байна...</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchProductCat;
