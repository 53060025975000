import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { Button, Divider, Flex, Radio } from "antd";
import secureLocalStorage from "react-secure-storage";
import { PostApi, GetApi, PutApi } from "../../constant/Api";

const AddressModal = ({ isOpen, onClose, addressPk }) => {
  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = secureLocalStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : {};
  });

  const [userLocation, setUserLocation] = useState();
  const handleLocationChange = (e) => {
    setUserLocation(e.target.value);

    const { name, value } = e.target;
    console.log(name, value);
  };

  const [userAddress, setUserAddress] = useState();
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    GetApi(`get_UserAddress/${userInfo.id}/`)
      .then((val) => {
        setUserAddress(
          val.dtl.filter((addr) => addr.id === parseInt(addressPk))[0]
        );
      })
      .catch((error) => {
        setUserAddress(null);
        alert(error);
      });
    GetApi(`get_locations/`).then((val) => {
      setLocations(val.dtl);
      console.log(val.dtl);
      //   console.log(
      //     val.dtl.filter((loc) => loc.id === userAddress.TCCityLocation)[0]
      //   );
      //   setUserLocation(
      //     val.dtl.filter((loc) => loc.id === userAddress.TCCityLocation)[0]
      //   );
    });
  }, []);

  const [selectAdd, setSelectAdd] = useState("Гэр");

  const handleSizeChange = (name) => {
    setSelectAdd(name);
    setUserAddress({ ...userAddress, ["TCAddressName"]: name });

    console.log(name);
  };

  const saveAddress = (e) => {
    setUserInfo({ ...userInfo, ["TCUserPk"]: addressPk });
    PutApi(`put_UserAddress/${addressPk}/`, JSON.stringify(userAddress)).then(
      (val) => {
        console.log(val);
      }
    );
    console.log(userAddress);
    onClose(e);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserAddress({ ...userAddress, [name]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Quick View Modal"
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full"
      //   className="absolute border top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/3 h-64 md:w-1/3 bg-white rounded-lg shadow-lg"
    >
      <div className="w-2/3 mx-auto p-5 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between">
          <div className="m-4 w-1/2">
            <h2 className="font-bold text-xl mb-4">Хаягийн мэдээлэл</h2>
            <div className="grid gap-4">
              <div className="flex w-full justify-between">
                <Button
                  type={selectAdd === "Гэр" ? "primary" : "default"}
                  onClick={() => handleSizeChange("Гэр")}
                  style={{ width: "33%" }}
                >
                  Гэр
                </Button>
                <Button
                  type={selectAdd === "Ажил" ? "primary" : "default"}
                  onClick={() => handleSizeChange("Ажил")}
                  style={{ width: "33%" }}
                >
                  Ажил
                </Button>
                <Button
                  type={selectAdd === "Бусад" ? "primary" : "default"}
                  onClick={() => handleSizeChange("Бусад")}
                  style={{ width: "33%" }}
                >
                  Бусад
                </Button>
              </div>
              <div>
                <label className="block mb-2">Хот/Аймаг</label>
                <div className="relative">
                  <select
                    name="TCCityLocation"
                    value={userLocation}
                    onChange={handleInputChange}
                    className="block appearance-none w-full border border-zinc-300 p-2 pr-8 rounded-lg leading-tight focus:outline-none"
                  >
                    {locations.map((loc) => (
                      <option key={loc.id} value={loc.id}>
                        {loc.name}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-zinc-700 pointer-events-none">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7.707 14.707A1 1 0 0117 10A1 1 0 117.707.293l-4.5 4.5A1 1 0 013.707 6.707L1.707 8.707l-.707-.707L.707 9.707l.707.707 1-.7072.91.91A1 1 0 017.707 14.707z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div>
                <label
                  //   htmlFor="detailed-address"
                  className="block mb-2 font-semibold"
                >
                  Дэлгэрэнгүй хаяг
                </label>
                <textarea
                  id="detailed-address"
                  placeholder="Detailed address info..."
                  className="border-2 border-zinc-300 p-2 rounded-lg w-full h-36"
                  value={userAddress != null ? userAddress.TCDetailAddress : ""}
                  name="TCDetailAddress"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="flex mt-4 justify-evenly">
              <Button
                type="primary"
                style={{ width: "40%" }}
                onClick={(e) => onClose(e)}
              >
                Буцах
              </Button>
              <Button
                type="primary"
                style={{ backgroundColor: "green", width: "40%" }}
                onClick={(e) => saveAddress(e)}
              >
                Хадгалах
              </Button>
            </div>
          </div>
          <div className="border-l-2 my-4 border-black"></div>
          <div className="grid grid-cols-1 w-1/2">
            <div className="mx-10 mb-12 mt-4">
              <h3 className="font-bold text-xl mb-4">
                Google map дээр хаягаа оруулна уу
              </h3>
              <iframe
                className="w-full h-5/6 rounded-3xl border-2 shadow-xl"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d397.53569194361546!2d106.88354062273578!3d47.91141334127768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693f9c13f404d%3A0x853c8dfc8c0cad06!2sKACC.MN%20Kassiin%20mashinii%20hudaldaa!5e0!3m2!1sen!2smn!4v1714554775336!5m2!1sen!2smn"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddressModal;
