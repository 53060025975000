import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter, Route, Link } from "react-router-dom";
import NumberFormatExample from "../constant/NumberFormatExample";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from "../components/Product";
import { productData, responsive } from "../data/data";
import noImg from "../images/no-image.png";

const ProductContainer = ({
  addProductToCart,
  dBproduct,
  dBgroup,
  isMain,
  dBDiscount,
}) => {
  const [Glitem, setGlitem] = useState([]);

  const getFirstPart = (catId) => {
    if (typeof catId === "string") {
      return catId.split(",")[0];
    }
    return "";
  };

  const getSecondPart = (catId) => {
    if (typeof catId === "string") {
      return catId.split(",")[1];
    }
    return "";
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const product = (id) => {
    console.log(id);
    const foundGroup = dBgroup.find((Gitem) => Gitem.catId === id);
    if (foundGroup) {
      return dBproduct.map(
        (item) =>
          item.catId[0] === foundGroup.catId && (
            <Product
              key={item.id}
              addProductToCart={addProductToCart}
              item={item}
            />
          )
      );
    }
  };

  const renderCarousel = (id) => {
    const products = product(id).filter((item) => item !== false);

    console.log(products);
    if (products && products.length > 0) {
      return (
        <Carousel
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
        >
          {products}
        </Carousel>
      );
    } else {
      return <div>Тухайн тасагт бараа бүртгэгдээгүй байна</div>;
    }
  };

  return (
    <section className="mx-5" id="Cart">
      <div className="col-span-5 sm:col-span-4">
        {isMain ? (
          <div className="w-full h-fit">
            {dBgroup.map((Gitem) => (
              <div key={Gitem.catId} className="mb-10">
                <div className="flex flex-row justify-between items-start md:items-end">
                  <div className="text-lg font-semibold md:text-3xl mb-2 md:mb-0">
                    {Gitem.catName}
                  </div>
                  <div className="text-sm md:text-lg hover:text-green-600">
                    <Link
                      to={{
                        pathname: `/ProductCat/${Gitem.catId}/${0}`,
                        state: { productData: Gitem },
                      }}
                    >
                      ...Бүгдийг харах
                    </Link>
                  </div>
                </div>
                <div className="pb-8">
                  <div className="w-full border-b border-green-600"></div>
                </div>
                {renderCarousel(Gitem.catId)}
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {dBproduct != null
              ? dBproduct.map((item) => (
                  <div
                    key={item.id}
                    className="h-48 md:h-auto bg-white border border-gray-200 rounded shadow-sm transition-transform duration-300 hover:transform hover:scale-105"
                  >
                    <Link
                      to={{
                        pathname: `/product-detail/${item.itemCode}`,
                        state: { productData: item },
                      }}
                      className="relative block overflow-hidden"
                    >
                      <img
                        alt={item.name}
                        src={item.imgs[0] || noImg}
                        className="object-contain w-full h-48 md:h-64"
                      />
                      <div className="absolute inset-0 bg-black/30 flex items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
                        <button className="bg-lime-600 text-white py-2 px-5">
                          Дэлгэрэнгүй
                        </button>
                      </div>
                      {item.discountPrice > 0 && (
                        <div className="flex absolute top-3 right-3 bg-green-600 text-white text-xs font-semibold px-2 py-1 rounded">
                          -
                          {NumberFormatExample(item.price - item.discountPrice)}
                          ₮
                        </div>
                      )}
                      {item.qty === 0 && (
                        <div className="absolute top-3 right-3 bg-red-600 text-white text-xs font-semibold px-2 py-1 rounded">
                          Дууссан
                        </div>
                      )}
                    </Link>
                    <div className="p-3">
                      <div className="grid items-center justify-between mb-2">
                        {item.discountPrice > 0 ? (
                          <>
                            <div className="flex">
                              <span className="flex line-through text-gray-500 text-sm">
                                {NumberFormatExample(item.price)}₮
                              </span>
                              <span className="flex font-bold text-lg">
                                {NumberFormatExample(item.discountPrice)}₮
                              </span>
                            </div>
                          </>
                        ) : (
                          <span className="flex font-bold text-lg">
                            {NumberFormatExample(item.price)}₮
                          </span>
                        )}
                        <div>Үлд: {item.qty}ш</div>
                      </div>
                      <h3 className="text-sm truncate">{item.name}</h3>
                      <button
                        onClick={() => addProductToCart(item)}
                        className="mt-2 w-full h-9 bg-gray-100 text-sm text-body-dark rounded hover:bg-green-600 hover:text-white transition-colors"
                      >
                        Сагслах
                      </button>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductContainer;
