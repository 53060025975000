import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { PiDotOutlineFill } from "react-icons/pi";
import gGroups from "../data/category.json";

import { Url, token } from "../context/SampleContext";

const CategoryDtlMobile = ({ dBgroup }) => {
  const [posts, setPosts] = useState([]);
  const [catId, setCatId] = useState(null);
  const [catDtlId, setCatDtlId] = useState(null);

  useEffect(() => {
    getQuote();
  }, []); // Empty dependency array since getQuote doesn't depend on any prop or state

  const getQuote = () => {
    // const token = "token b84cf4f51d8a5ad606ece08a8b2d72bdb8f3c1db";
    const axiosInstance = axios.create({
      headers: {
        Authorization: `${token}`,
      },
    });

    axiosInstance
      .get("https://invoice.kacc.mn/get_GroupHDR/")
      .then((response) => {
        setPosts(response.data.dtl);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    console.log(posts);
  }, [posts]); // Logging posts in a separate useEffect with posts as dependency

  return (
    // <div className="absolute mx-[170px] h-[550px] bg-slate-100 right-0 left-0 rounded-b-2xl">
    // <div className="grid grid-cols-5 gap-3 py-2 text-sm text-gray-600 p-5">
    <div>
      {dBgroup.map((category) => (
        <div key={category.catId}>
          {/* Render the category name */}
          <div className="flex justify-between">
            <div className="text-base font-bold mb-3">{category.catName}</div>
            <div className="">{"->"}</div>
          </div>
          {/* <ul>
              {Object.values(category.catDtl[0]).map((detail, index) => (
                <li className="flex" key={index}>
                  <PiDotOutlineFill className="mr-1 mt-1" />
                  <a
                    href={`/ProductCat/${category.catId}/${index + 1}`}
                    className="hover:text-green-600"
                  >
                    {detail}
                  </a>
                </li>
              ))}
            </ul> */}
        </div>
      ))}
    </div>
    // <div className="col-span-1 border mx-5 p-5">
    //   <div className="text-lg font-bold mb-3 border-b pb-1 text-gray-500">
    //     Ангилал
    //   </div>
    //   <div className="p-2">
    //     {dBgroup.map((category) => (
    //       <div key={category.catId}>
    //         <div
    //           className={`text-base text-gray-500 pt-3 font-semibold${
    //             catId == category.catId ? "font-semibold text-green-600" : ""
    //           }`}
    //         >
    //           {catId == category.catId ? (category.isShowMenu = true) : null}
    //           <a
    //             href={`/ProductCat/${category.catId}/0`}
    //             className="flex justify-between"
    //           >
    //             <div>
    //               <button
    //                 className="focus:outline-none"
    //                 onClick={() => handleButtonClick(category.catId)}
    //               >
    //                 {category.catName}
    //               </button>
    //             </div>
    //             <button
    //               className="focus:outline-none"
    //               onClick={() => handleButtonClick(category.catId)}
    //             >
    //               {category.isShowMenu ? (
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   fill="none"
    //                   viewBox="0 0 24 24"
    //                   strokeWidth={1.5}
    //                   stroke="currentColor"
    //                   className="w-6 h-6"
    //                 >
    //                   <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     d="m4.5 15.75 7.5-7.5 7.5 7.5"
    //                   />
    //                 </svg>
    //               ) : (
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   fill="none"
    //                   viewBox="0 0 24 24"
    //                   strokeWidth={1.5}
    //                   stroke="currentColor"
    //                   className="w-5 h-5"
    //                 >
    //                   <path
    //                     strokeLinecap="round"
    //                     strokeLinejoin="round"
    //                     d="m19.5 8.25-7.5 7.5-7.5-7.5"
    //                   />
    //                 </svg>
    //               )}
    //             </button>
    //           </a>
    //         </div>
    //         {/* <ul>
    //           {category.isShowMenu
    //             ? Object.entries(category.catDtl[0]).map(
    //                 ([key, value], index) => (
    //                   <div>
    //                     <li className="flex text-gray-500" key={index}>
    //                       <div className="pl-5 mr-3">-</div>
    //                       <a
    //                         href={`/ProductCat/${category.catId}/${key}`}
    //                         className={`${
    //                           catDtlId == key && catId == category.catId
    //                             ? "font-semibold text-green-600"
    //                             : "hover:text-green-600"
    //                         }`}
    //                       >
    //                         {value}
    //                       </a>
    //                     </li>
    //                     <div className="mx-5 border-b" />
    //                   </div>
    //                 )
    //               )
    //             : null}
    //         </ul> */}
    //       </div>
    //     ))}
    //   </div>
    // </div>
    // </div>
  );
};

export default CategoryDtlMobile;
