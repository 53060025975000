import { useState } from "react";

const Url = 'http://localhost:8000';
let APIURL = "invoice.kacc.mn";
const token = "token ac6b32bfa0847e59640e363cbaec448e4321d510"
// let APIURL = 'localhost:8000';

// Define a function component to use useState hook
function SampleComponent() {
  const [isAddress, setIsAddress] = useState(0);

  
  return { isAddress, setIsAddress };
}

function OderNumber() {
  const [orderNum, setOrderNum] = useState(0);

  
  return { orderNum, setOrderNum };
}

function formatDate(isoDate) {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export { APIURL, token, SampleComponent, OderNumber, formatDate };