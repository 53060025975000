import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { PostApi, GetApi, PutApi } from "../constant/Api";
import NumberFormatExample from "../constant/NumberFormatExample";
import { Link } from "react-router-dom";
import { QRCode } from "antd";
import icon from "../images/Logo.png";
import PayCheck from "./layout/PayCheck";

const Qpay = ({ products, tot, dBproduct }) => {
  const [userOrder, setUserOrder] = useState(() => {
    const storedUserOrder = secureLocalStorage.getItem("userOrder");
    return storedUserOrder ? JSON.parse(storedUserOrder) : null;
  });
  return (
    <div class="mt-40 mx-36 grid grid-flow-row-dense grid-cols-3 grid-rows-3  text-lg">
      <div class="col-span-2 m-6 ">
        <h5 className="  font-bold text-xl">QPAY Төлбөр төлөх</h5>
        <div className="grid text-start">
          <div className="flex pt-5 justify-center ">
            <QRCode
              size={300}
              errorLevel="H"
              value="https://kacc.mn/"
              icon={icon}
            />
          </div>
          <div className="bg-green-400 border rounded-md p-3 m-10">
            <div className="text-sm">
              • Та аль ч банкны аппликэшнээс QPay-г сонгон дээр байгаа QR кодыг
              уншуулан төлбөрөө төлөөрэй.
            </div>
            <div className="text-sm">
              • Төлбөр төлсөн бол Төлбөр шалгах товчийг дарна уу.
            </div>
            <div className="text-sm">
              • Танд ямар нэг асуух зүйл байгаа бол манай 7766-9002 утсаар
              хологдоно уу.
            </div>
          </div>
        </div>
      </div>
      <div className=" m-6 ">
        <h5 className="  font-bold text-xl">Төлбөрийн мэдээлэл</h5>
        <div class="border-2 mt-5 rounded-xl shadow-sm p-3">
          <div className="font-semibold border-b pb-2">Бүтээгдэхүүн</div>
          {products.map((item) => (
            <div className="flex justify-between pt-2">
              <div className="flex justify-between w-80">
                <div className="flex text-gray-500">{item.name}</div>
                <div className="text-gray-500 border rounded-md w-6 text-center bg-slate-200">
                  x{item.count}
                </div>
              </div>
              <div className="flex pl-10 font-medium">
                {NumberFormatExample(parseFloat(item?.price) * item?.count)}₮
              </div>
            </div>
          ))}
          <div>
            <div className="border-t my-3" />
            <div className="flex justify-between">
              <div className="font-semibold">Нийт төлөх дүн</div>
              <div className="flex pl-10 font-bold">
                {NumberFormatExample(tot)}₮
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1">
          <button
            onClick={() => PayCheck(userOrder.TCOrderNumber)}
            className=" bg-green-600 text-white  p-3 mt-4 rounded-lg "
          >
            <Link to="/">Төлбөр шалгах</Link>
          </button>
          <button className=" bg-white text-black border-2 border-slate-400 p-3 mt-4 rounded-lg ">
            Өмнөх алхам руу буцах
          </button>
        </div>
      </div>
    </div>
  );
};

export default Qpay;
