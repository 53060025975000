import "./App.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {
  Header,
  Footer,
  ProgrammScreen,
  MainContainer,
  About,
  Contact,
  PayOut,
  PriceOffer,
  SearchProductCat,
} from "./Pages";
import ProductDetail from "./Pages/ProductDetail";
import { CartProvider } from "./CartContext";
import BottomContainer from "./components/BottomContainer";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import Profile from "./Pages/Profile";
import OrderHistory from "./Pages/OrderHistory";
import OrderDetail from "./Pages/OrderDetail";
import Wishlist from "./Pages/Wishlist";
import Download from "./Pages/Download";
import CartProductPin from "./components/CartProductPin";
import ProductCat from "./Pages/ProductCat";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { successToast, errorToast } from "./constant/ReacrToast";
import SocialContact from "./components/SocialContact";
import OrderAddress from "./components/OrderAddress";
import Payments from "./components/Payments";
import Qpay from "./components/Qpay";
import Bank from "./components/Bank";
import { APIURL } from "./context/SampleContext";
import StateContextProvider from "./constant/stateContext";

import secureLocalStorage from "react-secure-storage";

export const Context = React.createContext();

function App() {
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     // Remove userInfo from localStorage
  //     secureLocalStorage.removeItem("userInfo");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const [cartsVisibilty, setCartVisible] = useState(false);
  const [productsInCart, setProducts] = useState(
    JSON.parse(localStorage.getItem("shopping-cart")) || []
  );

  const [tot, setTot] = useState(0);
  // let APIURL = "invoice.kacc.mn";
  // let APIURL = "127.0.0.1:8000";

  useEffect(() => {
    console.log(tot);
    localStorage.setItem("shopping-cart", JSON.stringify(productsInCart));
    let totalPrice = productsInCart.reduce(function (accumulator, item) {
      return (
        accumulator +
        item.count *
          (item.discountPrice === 0 ? item.price : item.discountPrice)
      );
    }, 0);
    setTot(totalPrice);
  }, [productsInCart]);

  const addProductToCart = (product) => {
    const itemIndex = productsInCart.findIndex(
      (item) => item.itemCode === product.itemCode
    );
    let newProduct = {
      ...product,
      count: 0,
    };

    if (product.qty > 0) {
      if (itemIndex !== -1) {
        const updatedItem = {
          ...productsInCart[itemIndex],
          count: productsInCart[itemIndex].count + 1,
        };
        if (product.qty >= updatedItem.count) {
          onProductRemove(updatedItem);
          setTimeout(() => {
            setProducts([...productsInCart, updatedItem]);
          }, 100);

          successToast(`Амжилттай: ${product.name} тоо/ш нэмэгдлээ`);
        } else {
          errorToast("Уучлаарай үлдэгдэл хүрэхгүй байна");
        }
      } else {
        if (product.qty >= newProduct.count) {
          newProduct = {
            ...product,
            count: 1,
          };
          setProducts([...productsInCart, newProduct]);
          successToast(`Амжилттай: ${product.name} сагсанд нэмлээ`);
        } else {
          errorToast("Уучлаарай үлдэгдэл хүрэхгүй байна");
        }
      }
    } else {
      errorToast("Уучлаарай тухайн бараа дууссан байна");
    }
  };

  const onQuantityChange = (productId, action) => {
    setProducts((oldState) => {
      const productsIndex = oldState.findIndex(
        (item) => item.itemCode === productId
      );
      if (productsIndex !== -1) {
        if (action) {
          if (oldState[productsIndex].qty > oldState[productsIndex].count) {
            oldState[productsIndex].count += 1;
          } else {
            errorToast("Уучлаарай үлдэгдэл хүрэхгүй байна");
          }
        } else {
          oldState[productsIndex].count -= 1;
        }
        if (oldState[productsIndex].count == 0) {
          console.log("productsIndex: " + oldState[productsIndex].name);
          onProductRemove(oldState[productsIndex]);
        }
      }

      return [...oldState];
    });
  };

  const onProductRemove = (product) => {
    setProducts((oldState) => {
      const productsIndex = oldState.findIndex(
        (item) => item.itemCode === product.itemCode
      );
      if (productsIndex !== -1) {
        oldState.splice(productsIndex, 1);
      }
      return [...oldState];
    });
  };
  const onProductClear = () => {
    setProducts([]);
  };

  const setOpen = (val) => {
    setCartVisible(val);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [dBCompany, setDBCompany] = useState([]);

  useEffect(() => {
    getCompay();
  }, []);

  const getCompay = () => {
    const token = "token ac6b32bfa0847e59640e363cbaec448e4321d510";
    const axiosInstance = axios.create({
      headers: {
        Authorization: `${token}`,
      },
    });

    axiosInstance
      .get(`https://${APIURL}/get_InvoiceSettings/`)
      .then((response) => {
        setDBCompany([]);
        setDBCompany(
          response.data.dtl.filter((item) => item.TCUseComp === true)
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    console.log(dBCompany);
  }, [dBCompany]);

  const [dBproduct, setDBproduct] = useState([]);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = () => {
    const token = "token ac6b32bfa0847e59640e363cbaec448e4321d510";
    const axiosInstance = axios.create({
      headers: {
        Authorization: `${token}`,
      },
    });

    axiosInstance
      .get(`https://${APIURL}/get_SiteProduct/0/`)
      .then((response) => {
        setDBproduct([]);
        setDBproduct(response.data.dtl);
        secureLocalStorage.setItem("pd", JSON.stringify(response.data.dtl));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    console.log(dBproduct);
  }, [dBproduct]);

  const [dBgroup, setDBgroup] = useState([]);

  useEffect(() => {
    getGroup();
  }, []);

  const getGroup = () => {
    const token = "token ac6b32bfa0847e59640e363cbaec448e4321d510";
    const axiosInstance = axios.create({
      headers: {
        Authorization: `${token}`,
      },
    });

    axiosInstance
      .get(`https://${APIURL}/get_GroupHDR/`)
      .then((response) => {
        setDBgroup(response.data.dtl);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    console.log(dBgroup);
  }, [dBgroup]);

  const [dBDiscount, setDBDiscount] = useState([]);

  useEffect(() => {
    GetDiscount();
  }, []);

  const GetDiscount = () => {
    const token = "token ac6b32bfa0847e59640e363cbaec448e4321d510";
    const axiosInstance = axios.create({
      headers: {
        Authorization: `${token}`,
      },
    });

    axiosInstance
      .get(`https://${APIURL}/get_DiscountProducts/`)
      .then((response) => {
        setDBDiscount(response.data.dtl);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    console.log(dBDiscount);
  }, [dBDiscount]);

  const [userData, setUserData] = useState({
    id: 0,
    TCUserName: "",
    TCEmail: "",
    TCPhoneNumber: "",
    TCImage: null,
    TCPassword: "",
    TCWhishLists: [],
  });
  return (
    <Context.Provider value={[userData, setUserData]}>
      <div className=" flex flex-col ">
        <Header dBgroup={dBgroup} dBDiscount={dBDiscount} />
        <ToastContainer />

        <main className="w-full h-full">
          <CartProvider>
            <Routes>
              <Route
                path="/*"
                element={
                  <MainContainer
                    addProductToCart={addProductToCart}
                    dBproduct={dBproduct}
                    dBgroup={dBgroup}
                  />
                }
              />
              <Route
                path="/product-detail/:id"
                element={
                  <ProductDetail
                    addProductToCart={addProductToCart}
                    dBproduct={dBproduct}
                    dBgroup={dBgroup}
                  />
                }
              />
              <Route path="/programmInfo" element={<ProgrammScreen />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/LoginPage" element={<Login />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/OrderHistory" element={<OrderHistory />} />
              <Route path="/OrderDetail/:id" element={<OrderDetail />} />
              <Route
                path="/Wishlist"
                element={
                  <Wishlist
                    addProductToCart={addProductToCart}
                    dBproduct={dBproduct}
                  />
                }
              />
              <Route
                path="/Download"
                element={<Download dBproduct={dBproduct} dBgroup={dBgroup} />}
              />
              <Route
                path="/PayOut"
                element={
                  <PayOut
                    products={productsInCart}
                    tot={tot}
                    dBproduct={dBproduct}
                    dBgroup={dBgroup}
                    onQuantityChange={onQuantityChange}
                    onProductClear={onProductClear}
                  />
                }
              />
              <Route
                path="/PriceOffer"
                element={
                  <PriceOffer
                    products={productsInCart}
                    tot={tot}
                    dBproduct={dBproduct}
                    dBCompany={dBCompany}
                  />
                }
              />
              <Route
                path="/OrderAddress"
                element={
                  <OrderAddress
                    products={productsInCart}
                    tot={tot}
                    dBproduct={dBproduct}
                  />
                }
              />
              <Route
                path="/Payments"
                element={
                  <Payments
                    products={productsInCart}
                    tot={tot}
                    dBproduct={dBproduct}
                  />
                }
              />
              <Route
                path="/Qpay"
                element={
                  <Qpay
                    products={productsInCart}
                    tot={tot}
                    dBproduct={dBproduct}
                    onProductClear={onProductClear}
                  />
                }
              />
              <Route
                path="/Bank"
                element={
                  <Bank
                    products={productsInCart}
                    tot={tot}
                    dBproduct={dBproduct}
                    onProductClear={onProductClear}
                  />
                }
              />
              <Route
                path="/ProductCat/:catId/:catDtlId"
                element={
                  <ProductCat
                    addProductToCart={addProductToCart}
                    dBproduct={dBproduct}
                    dBgroup={dBgroup}
                    isMain={false}
                    dBDiscount={dBDiscount}
                  />
                }
              />

              <Route
                path="/search"
                element={
                  <SearchProductCat
                    addProductToCart={addProductToCart}
                    dBproduct={dBproduct}
                    dBgroup={dBgroup}
                    isMain={false}
                  />
                }
              />
            </Routes>
          </CartProvider>

          <CartProductPin
            visibilty={cartsVisibilty}
            products={productsInCart}
            onClose={setOpen}
            onQuantityChange={onQuantityChange}
            onProductRemove={onProductRemove}
            onProductClear={onProductClear}
            tot={tot}
            qty={
              productsInCart.length > 0 ? (
                <span>{productsInCart.length}</span>
              ) : (
                <span>0</span>
              )
            }
          />
          <SocialContact />
        </main>
        <BottomContainer />
        <Footer />
      </div>
    </Context.Provider>
  );
}

export default App;
