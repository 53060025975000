import { GoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";

const clientId =
  "932737388320-ltbl50cuo9hgvag198hpnvdrqtc3a23r.apps.googleusercontent.com";

function GoogleLogOutBtn() {
  const navigate = useNavigate();
  const onSuccess = () => {
    sessionStorage.setItem("globalVariable", JSON.stringify([]));
    console.log("Log out successfull!");
    navigate("/#");
  };
  return (
    <div>
      <button className="mx-10 h-16 rounded-xl">
        <GoogleLogout
          clientId={clientId}
          buttonText="LogOut"
          onLogoutSuccess={onSuccess}
        />
      </button>
    </div>
  );
}

export default GoogleLogOutBtn;
