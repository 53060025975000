import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { AiOutlineSearch, AiFillHome } from "react-icons/ai";
import secureLocalStorage from "react-secure-storage";

const BottomContainer = () => {
  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = secureLocalStorage.getItem("userInfo");
    console.log(JSON.parse(storedUserInfo));
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });
  return (
    <div className="visible h-12 md:h-14 lg:hidden ">
      <nav className="fixed bottom-0 z-10 flex px-5 h-12 w-full justify-between bg-slate-100 py-1.5 shadow-400 ltr:left-0 rtl:right-0 md:h-14">
        <a
          href="/"
          class="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-0"
          tabindex="0"
        >
          <span class="sr-only">Home</span>
          <AiFillHome className="fill-green-600" size={25} />
        </a>
        <a
          class="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-0"
          tabindex="0"
        >
          <span class="sr-only">Search</span>
          <AiOutlineSearch className="fill-green-600" size={30} />
        </a>

        <a
          href={userInfo != null ? "/Profile" : "/LoginPage"}
          class="flex h-full items-center justify-center p-2 focus:text-accent focus:outline-0"
          tabindex="0"
        >
          <span class="sr-only">User</span>
          <FaUser className="fill-green-600" size={25} />
        </a>
      </nav>
    </div>
  );
};

export default BottomContainer;
