import React, { useEffect, useState } from "react";
import NumberFormatExample from "../constant/NumberFormatExample";
import { motion } from "framer-motion";
import { RiRefreshFill } from "react-icons/ri";
import QuickViewModal from "../components/QuickViewModal";
import { useNavigate } from "react-router-dom";
import { successToast, errorToast } from "../constant/ReacrToast";
import secureLocalStorage from "react-secure-storage";
import { GetApi } from "../constant/Api";
import { OderNumber } from "../context/SampleContext";

const PayOut = ({
  products,
  tot,
  dBgroup,
  onQuantityChange,
  onProductClear,
}) => {
  useEffect(() => {
    console.log(products);
  }, [products]);

  const findCat = (prod) => {
    const response = dBgroup.filter(
      (obj) => obj.catId === parseInt(prod.catId[1][0])
    );
    if (response.length > 0) {
      return response[0].catName;
    } else {
      console.error(`Category not found for product with catId: ${prod.catId}`);
      return "Unknown Category";
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const navigate = useNavigate();

  const closeModal = (
    e,
    companyName,
    companyRegister,
    companyPhone,
    companyMail
  ) => {
    e.preventDefault();
    setIsModalOpen(false);
    setModalData({ companyName, companyRegister, companyPhone, companyMail });
    handleSearchSubmit(
      e,
      companyName,
      companyRegister,
      companyPhone,
      companyMail
    );
  };

  const handleSearchSubmit = (
    e,
    companyName,
    companyRegister,
    companyPhone,
    companyMail
  ) => {
    if (e) {
      e.preventDefault();
    }
    navigate(`/PriceOffer`, {
      state: { companyName, companyRegister, companyPhone, companyMail },
    });
  };

  const [userInfo, setUserInfo] = useState(() => {
    const storedUserInfo = secureLocalStorage.getItem("userInfo");
    return storedUserInfo ? JSON.parse(storedUserInfo) : null;
  });

  const { orderNum, setOrderNum } = OderNumber();

  const payProducts = () => {
    if (userInfo != null) {
      GetApi(`get_InvoiceNumber/S${userInfo.id}/1/`).then((val) => {
        if (val.statusCode == 200) {
          secureLocalStorage.setItem("orderNum", JSON.stringify(val.dtl));
          navigate(`/OrderAddress`);
        } else {
          errorToast("Алдаа гарлаа та ахин оролдоно уу!");
        }
      });
    } else {
      errorToast(
        "Өөрийн хэрэглэгчээр нэвтэрсэний дараа худалдан авалт хийнэ үү!"
      );
      navigate(`/Login`);
    }
  };

  return (
    <div className="container mx-auto mt-40 px-4">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-2">
          <div className="flex justify-between items-center border rounded-md bg-green-600 text-white px-3 h-12">
            <div>Таны сагс</div>
            <div>
              <motion.p
                whileTap={{ scale: 0.75 }}
                className="flex items-center gap-2 p-1 px-2 my-2 rounded-md hover:shadow-md cursor-pointer text-textColor text-base"
                onClick={onProductClear}
              >
                Сагс хоослох <RiRefreshFill />
              </motion.p>
            </div>
          </div>
          <div>
            {products.map((item) => (
              <div
                key={item.itemCode}
                className="grid grid-cols-1 md:grid-cols-6 items-center justify-center my-5 p-4 border-2 rounded-xl shadow-sm"
              >
                <div className="flex items-center justify-center w-full h-32 border rounded-xl">
                  <img
                    className="inset-0 w-28 h-28 object-cover"
                    src={item.imgs[0]}
                    alt={item.name}
                  />
                </div>
                <div className="mt-4 md:mt-0 md:ml-4">
                  <div className="text-gray-500 text-sm">{findCat(item)}</div>
                  <div className="text-lg">{item.name}</div>
                  <div className="flex mt-3">
                    <div>Өнгө:</div>
                    <div className="ml-1">{item.colors}</div>
                  </div>
                </div>
                <div className="mt-4 md:mt-0 md:ml-4">
                  <div className="flex text-xl font-bold">
                    <div className="flex ">
                      {item.discountPrice === 0
                        ? NumberFormatExample(item.price)
                        : NumberFormatExample(item.discountPrice)}
                      ₮
                    </div>
                    <div className="flex md:pl-5 pl-5">
                      {item.count !== 1 ? (
                        <button
                          className="bg-gray-200 text-gray-600 px-2 rounded-l-lg hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-400"
                          onClick={() => onQuantityChange(item.itemCode, false)}
                        >
                          -
                        </button>
                      ) : (
                        <button className="bg-gray-300 text-gray-600 px-2 rounded-l-lg hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-400">
                          -
                        </button>
                      )}
                      <input
                        type="text"
                        className="w-10 text-center text-md border-gray-200 focus:border-gray-400 focus:outline-none"
                        value={item.count}
                        readOnly
                      />
                      <button
                        className="bg-gray-200 text-gray-600 px-2 rounded-r-lg hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-400"
                        onClick={() => onQuantityChange(item.itemCode, true)}
                      >
                        +
                      </button>
                    </div>
                    <div className="flex md:pl-5 pl-5">
                      {item.discountPrice === 0
                        ? NumberFormatExample(
                            parseFloat(item?.price) * item?.count
                          )
                        : NumberFormatExample(
                            parseFloat(item?.discountPrice) * item?.count
                          )}
                      ₮
                    </div>
                  </div>
                  <div className="flex">
                    <div className="text-red-600">Үлдэгдэл:</div>
                    <div className="ml-1 text-red-600">{item.qty}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center border rounded-md bg-green-600 text-white px-3 h-12">
            <div>Төлбөрийн мэдээлэл</div>
          </div>
          <div className="border-2 mt-5 rounded-xl shadow-sm p-3">
            <div className="font-semibold border-b pb-2">Бүтээгдэхүүн</div>
            {products.map((item) => (
              <div key={item.itemCode} className="flex justify-between pt-2">
                <div className="flex justify-between w-full md:w-80">
                  <div className="flex text-gray-500">{item.name}</div>
                  <div className="text-gray-500 border rounded-md w-6 text-center bg-slate-200">
                    x{item.count}
                  </div>
                </div>
                <div className="flex pl-10 font-medium">
                  {item.discountPrice === 0
                    ? NumberFormatExample(parseFloat(item?.price) * item?.count)
                    : NumberFormatExample(
                        parseFloat(item?.discountPrice) * item?.count
                      )}
                  ₮
                </div>
              </div>
            ))}
            <div>
              <div className="border-t my-3" />
              <div className="flex justify-between">
                <div className="font-semibold">Нийт төлөх дүн</div>
                <div className="flex pl-10 font-bold">
                  {NumberFormatExample(tot)}₮
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={openModal}
            className="border-2 mt-5 w-full rounded-xl shadow-sm p-3 bg-green-600 text-center text-white font-semibold"
          >
            Нэхэмжлэл авах
          </button>
          <button
            onClick={() => payProducts()}
            className="border-2 mt-5 w-full rounded-xl shadow-sm p-3 bg-green-600 text-center text-white font-semibold"
          >
            Төлбөр төлөх
          </button>
          <QuickViewModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default PayOut;
