import React, { useEffect, useState } from "react";
import axios from "axios";
import HomeContainer from "./HomeContainer";
import ProductContainer from "./ProductContainer";
import ImgSwiper from "../components/ImgSwiper";
import MainSlide from "../components/MainSlide";
import jsData from "../data/product.json";
import { Url } from "../context/SampleContext";

const MainContainer = ({ addProductToCart, dBproduct, dBgroup }) => {
  // const getQuote = () => {
  //   const token = "token b84cf4f51d8a5ad606ece08a8b2d72bdb8f3c1db";
  //   const axiosInstance = axios.create({
  //     headers: {
  //       Authorization: `${token}`,
  //     },
  //   });

  //   axiosInstance
  //     .get(`${Url}/get_GroupHDR/`)
  //     .then((response) => {
  //       try {
  //         // setGlobalGroup(response.data.dtl);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //       console.log("gGroup: ");
  //       // console.log(globalGroup);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };
  return (
    <div className="">
      <MainSlide />
      <HomeContainer dBgroup={dBgroup} />
      <div className="md:pt-10 md:mx-32">
        <ProductContainer
          addProductToCart={addProductToCart}
          dBproduct={dBproduct}
          dBgroup={dBgroup}
          isMain={true}
        />
      </div>

      <ImgSwiper />
    </div>
  );
};

export default MainContainer;
