import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import { BsFacebook } from "react-icons/bs";
import footerJs from "../data/footer.json";
import { MobileFooter } from "../data/MoblieFooter";
import { MdPhone, MdMail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu, Switch } from "antd";

const Footer = () => {
  return (
    <footer className="w-full bg-slate-100">
      <div className="flex items-center justify-center border-t border-green-600 py-4">
        {/* <Typography
          variant="small"
          className="text-center text-xs font-normal text-blue-gray-900"
        >
          &copy; {new Date().getFullYear()}{" "}
          <a href="https://kacc.mn/">KACC.MN</a>. All rights reserved.
        </Typography> */}
      </div>
    </footer>
  );
};

export default Footer;
